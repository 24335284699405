export default function profileCustomization() {
    function waitCancelShippingModal(){
        var aTags = document.querySelectorAll(".c7-modal__content.c7-modal--club-cancel p");
        var searchText = "Are you sure that you want to cancel? You can always skip upcoming shipments to receive less wine without losing your club benefits.";
        var found;
        var finishWaiting = false;
      
        for (var i = 0; i < aTags.length; i++) {
          if (aTags[i].textContent == searchText) {
            found = aTags[i];
            finishWaiting = true;
            found.innerHTML = "Are you sure that you want to cancel? You can always skip upcoming shipments to receive less wine without losing your club benefits. Contact us at <a href='tel:+8667835826'>866–783-5826</a> or email us at <a href='mailto:mumm_club@mummnapa.com'>mumm_club@mummnapa.com</a> to skip upcoming shipments.";
            var buttonTags = document.querySelectorAll(".c7-modal__content.c7-modal--club-cancel .c7-form__buttons .c7-btn.c7-btn--alt");
            var searchTextButton = "Skip Shipment";
            for (var j = 0; j < aTags.length; j++) {
              if (buttonTags[j].textContent == searchTextButton) {
                found = buttonTags[j];
                found.style.display = "none";
              }
            }
          }
        }
        setTimeout(function(){
          waitCancelShippingModal();
        }, 100);
      }
    if (window.location.href.includes('/profile/club-membership')){
        waitCancelShippingModal();
    }
}


