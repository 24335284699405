export default function(){
    function getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
    }
    function parseJwt (token){
        try {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            return JSON.parse(jsonPayload);
        }catch(ex){
            return null;
        }
    }
    function checkClubExclusive(){
        var memberIsLoggedIn = false;
        var cookie = getCookie('customerToken');
        if (cookie && cookie!='' && cookie!=null){
            var payload = parseJwt(cookie);
            if (payload && payload.isClubMember != undefined){
                if(payload.isClubMember){
                    memberIsLoggedIn = true;
                }
            }
        }
        if(memberIsLoggedIn){
            var clubExclusives = document.querySelectorAll(".post-type-filter-grid-v2 .c7-product__login-message");
            for (let i = 0; i < clubExclusives.length; i++){
                clubExclusives[i].style.display = 'none'
                clubExclusives[i].parentElement.querySelector(".c7-product__variant__add-to-cart_replacement").style.display = 'flex';
            }
            clubExclusives = document.querySelectorAll(".product-info .c7-product__login-message");
            for (let i = 0; i < clubExclusives.length; i++){
                clubExclusives[i].style.display = 'none'
                clubExclusives[i].parentElement.querySelector(".c7-buy-product").style.display = 'block';
            }
            setTimeout(function(){
                checkClubExclusive();
            }, 100);
        }
    }
    checkClubExclusive();
}