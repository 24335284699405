export default function(){
    var zendesk_script = document.createElement('script');
    zendesk_script.setAttribute('src','https://static.zdassets.com/ekr/snippet.js?key=2409aa68-22e0-4725-a2df-563ee8e329bd');
    zendesk_script.setAttribute('id','ze-snippet');
    zendesk_script.setAttribute('loading','lazy');
    document.body.appendChild(zendesk_script);
    var settings_script = document.createElement('script');
    settings_script.type = 'text/javascript';
    settings_script.text = `
      window.zESettings = {
        webWidget: {
          chat: {
            departments: {
              enabled: []
            }
          }
        }
      };
    `;

    // Append the zESettings script to the body
    document.body.appendChild(settings_script);
}