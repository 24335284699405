

export default function checkout() {
    function isCommerce7V2(){
        const element = document.querySelector("#c7-javascript");
        return element.src.includes("v2")
    }

    function setWithExpiry(key, value, ttl) {
        const now = new Date()    
        const item = {
            value: value,
            expiry: now.getTime() + ttl,
        }
        localStorage.setItem(key, JSON.stringify(item))
    }

    function getWithExpiry(key) {
        const itemStr = localStorage.getItem(key)
        if (!itemStr) {
            return null
        }
        const item = JSON.parse(itemStr)
        const now = new Date()
        
        if (now.getTime() > item.expiry) {            
            localStorage.removeItem(key)
            return null
        }
        return item.value
    }

    function removeClassIfExists(element, className) {
        if (element.classList.contains(className)) {
          element.classList.remove(className);
        }
      }
    function removeHrefAndAddClickListener(element, clickHandler) {
        element.removeAttribute('href');
        element.addEventListener('click', clickHandler);
      }
      function removeElementIfExists(element) {
        if (element && element.parentNode) {
          element.parentNode.removeChild(element);
        }
      }
    function addClass(element, className) {
        if (!element.classList.contains(className)) {
          element.classList.add(className);
        }
    }

    function setReadOnly(element, readOnly) {
        element.readOnly = readOnly;
    }

    function onDocumentReady() {
        return new Promise(resolve => {
        if (document.readyState === "complete") {
            resolve();
        } else {
            window.addEventListener("load", () => {
            resolve();
            });
        }
        });
    }

    function insertAfter(newElement, referenceElement) {
        if (newElement && referenceElement){
            referenceElement.parentNode.insertBefore(newElement, referenceElement.nextSibling);
        }
    }

    function setStyle(element, style, value) {
        if (element){
            element.style[style] = value;
        }
    }

    function getElement(selector) {
        var res = document.querySelectorAll(selector);
        if (res && res.length>0){
            return res[0];
        }
        return undefined;
    }

    function emptyContents(element) {
        if (element){
            while (element.firstChild) {
                element.removeChild(element.firstChild);
            }
        }
    }

    function emptyContentsBySelector(selector) {
        if (selector && selector.trim()!=''){
            var element = getElement(selector);
            emptyContents(element);
        }
    }


    function elementExists(selector) {
        return document.querySelectorAll(selector).length > 0;
    }

    function setHtmlContent(element, html) {
    element.innerHTML = html;
    }

    function createElementFromHtml(htmlString) {
        return createElementFromHTML(htmlString);
    }

    function createElementFromHTML(htmlString) {
        if (htmlString && htmlString.trim!=''){
            const div = document.createElement('div');
            div.innerHTML = htmlString.trim();
            return div.firstChild;
        }
        return undefined;
    }

    function prependElement(parent, child) {
        parent.insertBefore(child, parent.firstChild);
    }

    function appendElement(parent, child) {
        if (parent && child){
            parent.appendChild(child);
        }
    }
    
    function getUppermostSectionInMain(button) {
        const main = document.querySelector('main');
      
        let section = null;
        let currentElement = button;
      
        while (currentElement && currentElement !== main) {
          if (currentElement.tagName === 'SECTION') {
            section = currentElement;
          }
          currentElement = currentElement.parentElement;
        }
      
        return section;
    }

    function addListeners(element, eventNames, listener) {
        if (element){
            var events = eventNames.split(' ');
            for (var i=0, iLen=events.length; i<iLen; i++) {
            element.addEventListener(events[i], listener, false);
            }
        }
    }

    function removeElement(element) {
        if (element){
            element.parentNode.removeChild(element);
        }
    }


    function findAncestor (el, sel) {
        while ((el = el.parentElement) && !((el.matches || el.matchesSelector).call(el,sel)));
        return el;
    }

    //requests


    function makeGetRequest(url) {
        var options = {
            headers:{

            }
        };
        if (_prusasearchoptions.token){
            options.headers['Authorization']='Bearer '+_prusasearchoptions.token
        }
        return fetch(url,options)
            .then(response => response.json())
            .catch(error => {
                //debugger;
                console.error(error);
            });
    }

    function makePostRequest(url, data) {
        return fetch(url, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        .then(response => response.json())
        .catch(error => console.error(error));
    }

    function makePutRequest(url, data) {
        return fetch(url, {
            method: 'PUT',
            headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        .then(response => response.json())
        .catch(error => console.error(error));
    }

    function makeDeleteRequest(url) {
        return fetch(url, {
            method: 'DELETE'
        })
        .then(response => response.json())
        .catch(error => console.error(error));
    }
    function isValidUSZipCode(zipCode) {
        // US zip codes can be either 5 digits or 5 digits followed by a hyphen and 4 more digits
        const zipCodeRegex = /^\d{5}(?:[-\s]\d{4})?$/;
        return zipCodeRegex.test(zipCode);
      }
    function enableJoinSubmitButton(selection){

        var submit = document.querySelector('.join-the-club-submit');
        if (submit.classList.contains('processing')){
            return;
        }
        if (selection=='ship'){
            var state = document.querySelector('.join-club-state');            
            
            if (state.value){                
                removeClassIfExists(submit,'disabled');                    
                return;
            }

            addClass(submit,'disabled');            
            
            return;
        }
        
        removeClassIfExists(submit,'disabled');
    }
    function submitToCheckout(parent,theButton){

        if (theButton.classList.contains('disabled') || theButton.classList.contains('processing')){
            return;
        }

        var submit = parent.querySelector('.join-the-club-submit');
        var popup = parent.querySelector('.join-club-popup');
        var clubName = popup.getAttribute('data-club-name');
        var selection = popup.getAttribute('data-id');

        var option = "";
        if (selection=="pickup"){
            option = "pickup";
        }else{
            var state = document.querySelector('.join-club-state');
            switch (state.value) {                
                case "Texas":
                case "Tennessee":
                case "Ohio": 
                    option="no-champagne";
                    break;
                default:
                    option="ship-champagne";
                    break;
            }
        }
        
        setWithExpiry('club-shipping-type',selection,60*60*1000);        
        addClass(theButton,"processing");

        addClass(popup,'processing');
        
        window.clubButtonClickInterval = setInterval(() => {
            
            var targetButton = parent.querySelector('.c7-club-join-button[data-club-name="'+clubName+'"][data-shipping-type="'+option+'"] a.c7-btn.c7-btn--primary');
            if(targetButton == null){
                targetButton = parent.querySelector('.c7-club-join-button[data-club-name="'+clubName+'"][data-shipping-type="'+option+'"] a.c7-button');
            }
            //console.log('clicked on '+targetButton.parentElement.getAttribute('data-club-slug'));
            if (targetButton){
                clearInterval(window.clubButtonClickInterval);
                targetButton.click();
            }
        }, 100);
     

    }
    function showClubSelectionPopup(clubName,parent){
        
        removeElementIfExists(document.querySelector('.join-club-popup-fullscreen-overlay'));
        var popup = `
            <div class="join-club-popup-fullscreen-overlay">        
                <div class="join-club-popup" data-club-name="${clubName}">                
                    <div class="spinner hidden">
                        <span>Processing</span>
                        <svg fill="#9e7952" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <style>.spinner_ajPY{transform-origin:center;animation:spinner_AtaB .75s infinite linear}@keyframes spinner_AtaB{100%{transform:rotate(360deg)}}
                            </style>
                            <path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity=".25"/>
                            <path d="M10.14,1.16a11,11,0,0,0-9,8.92A1.59,1.59,0,0,0,2.46,12,1.52,1.52,0,0,0,4.11,10.7a8,8,0,0,1,6.66-6.61A1.42,1.42,0,0,0,12,2.69h0A1.57,1.57,0,0,0,10.14,1.16Z" class="spinner_ajPY"/>
                        </svg>
                    </div>
                    <div class="header">
                        <label>
                            Select your shipping option
                        </label>
                        <a class="close-join-club-popup">
                            <svg aria-hidden="true" focusable="false" role="presentation" class="icon icon-close" viewBox="0 0 1024 1024" fill="#9e7952"><path d="M446.174 512L13.632 79.458c-18.177-18.177-18.177-47.649 0-65.826s47.649-18.177 65.826 0L512 446.174 944.542 13.632c18.177-18.177 47.649-18.177 65.826 0s18.177 47.649 0 65.826L577.826 512l432.542 432.542c18.177 18.177 18.177 47.649 0 65.826s-47.649 18.177-65.826 0L512 577.826 79.458 1010.368c-18.177 18.177-47.649 18.177-65.826 0s-18.177-47.649 0-65.826L446.174 512z"></path></svg>
                        </a>
                    </div>
                    <div class="options">
                        <button class="pickup content-v2__cta--primary split-promo__cta split-promo__cta--primary" data-id="pickup">
                            Pickup
                        </button>
                        <button class="ship content-v2__cta--primary split-promo__cta split-promo__cta--primary" data-id="ship">
                            Ship
                        </button>
                    </div>
                    <div class="additional">
                        <div class="pickup">
                            <label>
                            Pickup at:<br>
                            8445 Silverado Trail, Napa CA 94558,<br>
                            (800) 686-6272
                            </label>
                        </div>
                        <div class="ship">
                            <label> Select your state</label>
                            <br>
                            <select class="join-club-state">
                                <option value="Alaska">Alaska</option>
                                <option value="Arizona">Arizona</option>
                                <option value="California">California</option>
                                <option value="Colorado">Colorado</option>
                                <option value="Connecticut">Connecticut</option>
                                <option value="Florida">Florida</option>
                                <option value="Georgia">Georgia</option>
                                <option value="Hawaii">Hawaii</option>
                                <option value="Idaho">Idaho</option>
                                <option value="Illinois">Illinois</option>
                                <option value="Indiana">Indiana</option>
                                <option value="Iowa">Iowa</option>
                                <option value="Kansas">Kansas</option>
                                <option value="Louisiana">Louisiana</option>
                                <option value="Maine">Maine</option>
                                <option value="Maryland">Maryland</option>
                                <option value="Massachusetts">Massachusetts</option>
                                <option value="Michigan">Michigan</option>
                                <option value="Minnesota">Minnesota</option>
                                <option value="Missouri">Missouri</option>
                                <option value="Montana">Montana</option>
                                <option value="Nebraska">Nebraska</option>
                                <option value="Nevada">Nevada</option>
                                <option value="New Hampshire">New Hampshire</option>
                                <option value="New Mexico">New Mexico</option>
                                <option value="New York">New York</option>
                                <option value="North Carolina">North Carolina</option>
                                <option value="North Dakota">North Dakota</option>
                                <option value="Oregon">Oregon</option>
                                <option value="Pennsylvania">Pennsylvania</option>
                                <option value="South Carolina">South Carolina</option>
                                <option value="Tennessee">Tennessee</option>
                                <option value="Texas">Texas</option>
                                <option value="Vermont">Vermont</option>
                                <option value="Virginia">Virginia</option>
                                <option value="Washington">Washington</option>
                                <option value="West Virginia">West Virginia</option>
                                <option value="Wisconsin">Wisconsin</option>
                                <option value="Wyoming">Wyoming</option>     
                                <option value="DC">District of Columbia</option>                           
                            </select>
                        </div>
                    </div>
                    <div class="action">
                        <button class="join-the-club-submit disabled content-v2__cta--primary split-promo__cta split-promo__cta--primary">
                            Join the Club
                        </button>
                    </div>
                </div>        
            </div>                
        `;

        appendElement(parent,createElementFromHTML(popup));

        var closePopup = getElement('.close-join-club-popup');
        closePopup.addEventListener('click',function(e){            
            removeElementIfExists(document.querySelector('.join-club-popup-fullscreen-overlay'));
        });




        var buttons = document.querySelectorAll('.join-club-popup .options  button');

        buttons.forEach((button)=>{
            button.addEventListener('click',function(e){

                var buttons = document.querySelectorAll('.join-club-popup .options  button');
                buttons.forEach((e)=>{
                    removeClassIfExists(e,'active');
                });
                addClass(button,'active');
                var popup = document.querySelector('.join-club-popup');
                popup.setAttribute('data-id',button.getAttribute('data-id'));

                enableJoinSubmitButton(button.getAttribute('data-id'));
            });
        });

        var stateSelector = document.querySelector('.join-club-state');

        stateSelector.addEventListener('change',function(e){
            var popup = document.querySelector('.join-club-popup');
            var selection = popup.getAttribute('data-id');
            enableJoinSubmitButton(selection);
        });

        var submitButton = parent.querySelector('button.join-the-club-submit');
        submitButton.addEventListener('click',function(e){
            var parentElementForThePopup = document.querySelector('main.main-container')
            
            submitToCheckout(parentElementForThePopup,e.target);
        });

    }

    const noChampagneStates=[
        'Texas',
        'Tennessee',
        'Ohio'
    ];

    const states = [
        'Alabama',
        'Alaska',
        'Arizona',
        'Arkansas',
        'California',
        'Colorado',
        'Connecticut',
        'Delaware',
        'Florida',
        'Georgia',
        'Hawaii',
        'Idaho',
        'Illinois',
        'Indiana',
        'Iowa',
        'Kansas',
        'Kentucky',
        'Louisiana',
        'Maine',
        'Maryland',
        'Massachusetts',
        'Michigan',
        'Minnesota',
        'Mississippi',
        'Missouri',
        'Montana',
        'Nebraska',
        'Nevada',
        'New Hampshire',
        'New Jersey',
        'New Mexico',
        'New York',
        'North Carolina',
        'North Dakota',
        'Ohio',
        'Oklahoma',
        'Oregon',
        'Pennsylvania',
        'Rhode Island',
        'South Carolina',
        'South Dakota',
        'Tennessee',
        'Texas',
        'Utah',
        'Vermont',
        'Virginia',
        'Washington',
        'West Virginia',
        'Wisconsin',
        'Wyoming'
    ]


    console.log('checkout script loaded');
    function getQueryParams(url) {
        const paramArr = url.slice(url.indexOf('?') + 1).split('&');
        const params = {};
        paramArr.map(param => {
            const [key, val] = param.split('=');
            params[key] = decodeURIComponent(val);
        })
        return params;
    }

    
    
    var clubJoins = document.querySelectorAll('div.c7-club-join-button-replaced');



    if (clubJoins && clubJoins.length>0){   
        clubJoins.forEach(theButtonWrapper=>{            
            var clubName = theButtonWrapper.getAttribute('data-club-name');
            var theButton = theButtonWrapper.querySelector('a.join-club-button');

            theButton.addEventListener('click',function(e){
                //console.log('clicked '+e.target.getAttribute('data-club-slug'));
                var parentElementForThePopup = document.querySelector('main.main-container');//  getUppermostSectionInMain(theButtonWrapper);

                
                showClubSelectionPopup(clubName,parentElementForThePopup);
            });
        });
    }
    if (window.location.href.includes('/checkout/club/delivery')){
        window.checkC7Loaded = setInterval(function(){
            var shippingTab = document.querySelector('#c7-delivery-shipping-tab');
            var pickupTab = document.querySelector('#c7-delivery-pickup-tab');

            if (shippingTab && pickupTab){
                let preferredShipmentType = getWithExpiry('club-shipping-type');        
                
                if (preferredShipmentType && preferredShipmentType=='pickup'){                    

                        pickupTab.click();
                        setStyle(shippingTab,'display','none');               
                        addClass(shippingTab,'hidden');
                        

                        // setTimeout(function(){
                        //     pickupTab.click();
                        //     setStyle(shippingTab,'display','none');               
                        //     addClass(shippingTab,'hidden');
                        // },300);

                }else if (preferredShipmentType && preferredShipmentType=='ship' ){                    
                    
                    shippingTab.click();
                    setStyle(pickupTab,'display','none');            
                    addClass(pickupTab,'hidden');

                    // setTimeout(function(){
                    //     shippingTab.click();
                    //     setStyle(pickupTab,'display','none');            
                    //     addClass(pickupTab,'hidden');
                    // },300);

                }
                clearInterval(window.checkC7Loaded);
            }
        },100);
    }

    if (window.location.href.includes('/checkout/club/')){
        //we're on the checkout club page
        if (preferredShipmentType && preferredShipmentType=='pickup'){                    
            if(isCommerce7V2()){
                window.location.replace("/checkout/club/delivery");

            }
        }
        
        window.checkC7Loaded = setInterval(function(){
            var shippingTab = document.querySelector('#c7-delivery-shipping-tab');
            var pickupTab = document.querySelector('#c7-delivery-pickup-tab');

            if (shippingTab && pickupTab){
                let preferredShipmentType = getWithExpiry('club-shipping-type');        
                
                if (preferredShipmentType && preferredShipmentType=='pickup'){                    

                        pickupTab.click();
                        setStyle(shippingTab,'display','none');               
                        addClass(shippingTab,'hidden');
                        

                        // setTimeout(function(){
                        //     pickupTab.click();
                        //     setStyle(shippingTab,'display','none');               
                        //     addClass(shippingTab,'hidden');
                        // },300);

                }else if (preferredShipmentType && preferredShipmentType=='ship' ){                    
                    
                    shippingTab.click();
                    setStyle(pickupTab,'display','none');            
                    addClass(pickupTab,'hidden');

                    // setTimeout(function(){
                    //     shippingTab.click();
                    //     setStyle(pickupTab,'display','none');            
                    //     addClass(pickupTab,'hidden');
                    // },300);

                }
                clearInterval(window.checkC7Loaded);
            }
        },100);
        
        
    }
}


