import { fadeIn } from '@pernod-ricard-global-cms/jsanimations';
import { h } from 'vue';

export default function siteHeaderJs(options = {}) {
	try {
		// CSS for site-header should usually be included in critical.scss

		function init_top_right_icons() {
			// Function to show spinner and hide SVG
			const showSpinner = (elem) => {
				const svg = elem.querySelector('svg');
				// if (svg) svg.style.display = 'none';
				// Add a spinner here. You can use a div or an img tag, whatever you prefer.
				// elem.innerHTML += '<div class="spinner">Loading...</div>';
			};

			// Function to hide spinner and show SVG
			const hideSpinner = (elem) => {
				const svg = elem.querySelector('svg');
				const spinner = elem.querySelector('.spinner');
				if (svg) svg.style.display = 'block';
				if (spinner) spinner.remove();
			};


			const cartSubstituteLink = document.querySelector('a.c7-cart-substitute-link');
			let cartLink = document.querySelector('#c7-cart > div > button');

			console.log('click');

			if (cartSubstituteLink) {
				cartSubstituteLink.addEventListener('click', () => {
					// Check if #c7-cart > a is loaded
					cartLink = document.querySelector('#c7-cart > div > button');
					if (cartLink) {
						hideSpinner(cartSubstituteLink);
						// Simulate a click
						cartLink.click();

					} else {
						showSpinner(cartSubstituteLink);
					}
				});

				// Create a Mutation Observer to check when #c7-cart > a is added to the DOM
				const observer = new MutationObserver(() => {
					cartLink = document.querySelector('#c7-cart');
					if (cartLink) {
						hideSpinner(cartSubstituteLink);
						observer.disconnect(); // Stop observing since the element is found
					}
				});

				observer.observe(document.body, { childList: true, subtree: true });
			}

		}


		const { block } = options;
		fadeIn(block);


		init_top_right_icons();


		const documentBody = document.body;
		


		const hamburger = document.querySelector(
			'.header-interactions__hamburger'
		);


		if (hamburger && screen.width < 1024) {

			const hamburgerWrapper = document.querySelector(
				'.header-interactions__hamburger-wrapper'
			);

			const hamburgerBars = hamburger.querySelectorAll(
				'.header-interactions__hamburger-bar'
			);

			hamburgerWrapper.addEventListener('click', function () {
				if (screen.width < 1024) {
					
					hamburger.classList.toggle('active');
					hamburgerBars.forEach((item) => {
						item.classList.toggle('active');
					});
					documentBody.classList.toggle('mobile-menu-active');
					documentBody.classList.toggle('lock-position');
				}
			});

			const navContainers = document.querySelectorAll('.header-nav');


			navContainers.forEach((navContainer) => {

				const itemsWithChildren = navContainer.querySelectorAll(
					'a.menu-item-has-children'
				);
	
				const closeSubnavs = () => {
					navContainer
						.querySelectorAll('.header-nav__item.active')
						.forEach((el) => el.classList.remove('active'));
					documentBody.classList.remove('mobile-menu-active', 'lock-position');
				};
	
				itemsWithChildren.forEach((item) => {
					item.attributes['data-href']=item.attributes.href.value;
					item.attributes.href.value = 'javascript:void(0)';
					item.addEventListener('click', function (e) {
						e.preventDefault();
						const el = e.currentTarget;
	
						let active = false;
						if (el.parentNode.classList.contains('active')) {
							active = false;
						}else{
							active = true;
						}
	
						const parentUl = el.parentNode.parentNode; // Get the parent ul (level-2 ul)
						const siblings = parentUl.querySelectorAll('.header-nav__item.active'); // Select all active siblings at the same level
				
						// Loop through the siblings and remove the 'active' class
						siblings.forEach((sibling) => {
							sibling.classList.remove('active');
						});
						//debugger;
						//if parent node has active class remove it
	
						
						if (active) {
							el.parentNode.classList.add('active');							
						}else{						
							el.parentNode.classList.remove('active');					
						}
						
					});
				});
	
				window.addEventListener('resize', closeSubnavs, true);

			});


		}


	} catch (error) {
		console.error(error);
	}
}
