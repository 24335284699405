import {fadeIn,fadeOut} from '@pernod-ricard-global-cms/jsanimations';

export default function tockButtons() {
    
    function addClass(element, className) {
        if (!element.classList.contains(className)) {
          element.classList.add(className);
        }
    }
    function removeClassIfExists(element, className) {
        if (element.classList.contains(className)) {
          element.classList.remove(className);
        }
      }
    function handleClick(target){

        var button = target.querySelector('div.TockButton-link');        
        if (button) {
            button.click();
            var replacementButton = target.parentElement.querySelector('.tock.reservation_cta_button_replacement');
            removeClassIfExists(replacementButton,'processing');
            return;
        }

        setTimeout(function(){
            handleClick(target);
        },200);        

    }

    function init() {
        let anyTockWidgets = document.querySelectorAll('.tock.reservation_cta_button');
        if (anyTockWidgets && anyTockWidgets.length > 0) {

            anyTockWidgets.forEach((widget, index) => {

                var replacementButton = widget.parentElement.querySelector('.tock.reservation_cta_button_replacement');

                if (replacementButton) {
                    replacementButton.addEventListener('click', (e) => {
                        if (replacementButton.classList.contains('processing')) {
                            return;
                        }
                        addClass(replacementButton, 'processing');
                        handleClick(widget);
                    }, false);
                }

            });
            return true;
        }
        return false;
    }
    
    var properPage = window.location.href.includes('tours-tastings');
    
    if (properPage){
        if (!init()){
            setTimeout(function(){
                init();
            },1000)
        }
    }

    
    function handleStickyTockBookNow(){
        let parentSection = null;
        function waitForElementToLoad(selector,callback) {
            setTimeout(() => {						
                var x = document.querySelector(selector);                
                if (x){							
                    callback();
                }else{
                    waitForElementToLoad(selector,callback);
                }
            }, 1000);
        }				

        function addClass(element, className) {
            if (element.classList) {
                element.classList.add(className);
            } else {
                element.className += " " + className;
            }
        }

        function removeClass(element, className) {
        if (element.classList) {
            element.classList.remove(className);
        } else {
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
        }

        var viewPortObserver = new IntersectionObserver(function(entries) {
            entries.forEach(function(entry) {
                if (entry.isIntersecting) {
                    showStickyBar(entry.target);
                } else {
                    hideStickyBar(entry.target);
                }
            });
        });

        function showStickyBar(element) {            
            let block = document.querySelector('.sticky-book-tock');
            fadeOut(block);
            setTimeout(() => {
                removeClass(block,'visible');
                addClass(block,'hidden');	
            }, 300);				
        }
        function hideStickyBar(element) {            
            let block = document.querySelector('.sticky-book-tock');
            removeClass(block,'hidden');
            addClass(block,'visible');
            fadeIn(block);				
        }
        function findParentWithClassStartingFromElement(element, className) {            
            while (element && !element.classList.contains(className)) {
                element = element.parentElement;
            }            
            return element;
        }

        

        waitForElementToLoad(".reservation_cta_button_replacement",function(){
            let element = document.querySelector('.standard-content-v2 .reservation_cta_button_replacement');            
            
            parentSection =  findParentWithClassStartingFromElement(element,"standard-content-v2")
            
            
            let sticky = document.querySelector('.sticky-book-tock');			

            let title = sticky.querySelector('.title > h3');
            let description = sticky.querySelector('.description > p');
            
            let titleContent = parentSection.querySelector('.heading > h2');
            let descriptionContent = parentSection.querySelector('.standard-content-v2__content-container > p');

            if (titleContent && titleContent.innerText){
                title.innerText = titleContent.innerText;
            }

            if (descriptionContent && descriptionContent.innerText){
                description.innerText = descriptionContent.innerText;
            }

            viewPortObserver.observe(element);
            
        });
        
     
    }

    let sticky = document.querySelector('.sticky-book-tock');			
    if (sticky ){
         handleStickyTockBookNow();        
    }

}