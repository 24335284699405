//PR USA module for commerce 7 cart integration

export default function commerce7cart() {    
			function isCommerce7V2(){
				const element = document.querySelector("#c7-javascript");
				return element.src.includes("v2")
			}
			function waitForElement(selector, button, callback) {
				const element = document.querySelector(selector);
				
				if (element) {					
					callback(element);
					hideSpinner(button);
				} else {
					showSpinner(button);
					setTimeout(() => {
						waitForElement(selector,button, callback);
					}, 100); // Adjust the delay (in milliseconds) between checks if needed
				}
			}

			function waitForNestedElement(parentElement, selector, button, callback) {
				const element = parentElement.querySelector(selector);
				
				if (element) {				  
				  callback(element);
				  hideSpinner(button);
				} else {
				  showSpinner(button);
				  setTimeout(() => {
					waitForNestedElement(parentElement, selector, button, callback);
				  }, 100); // Adjust the delay (in milliseconds) between checks if needed
				}
			  }

			function showSpinner(button) {
				if (button){
					button.disabled = true;
					
					if (button.getAttribute('data-icon')){
						button.innerHTML = '<svg baseProfile="tiny" height="24px" version="1.2" viewBox="0 0 24 24" width="24px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><g><path d="M20.756,5.345C20.565,5.126,20.29,5,20,5H6.181L5.986,3.836C5.906,3.354,5.489,3,5,3H2.75c-0.553,0-1,0.447-1,1    s0.447,1,1,1h1.403l1.86,11.164c0.008,0.045,0.031,0.082,0.045,0.124c0.016,0.053,0.029,0.103,0.054,0.151    c0.032,0.066,0.075,0.122,0.12,0.179c0.031,0.039,0.059,0.078,0.095,0.112c0.058,0.054,0.125,0.092,0.193,0.13    c0.038,0.021,0.071,0.049,0.112,0.065C6.748,16.972,6.87,17,6.999,17C7,17,18,17,18,17c0.553,0,1-0.447,1-1s-0.447-1-1-1H7.847    l-0.166-1H19c0.498,0,0.92-0.366,0.99-0.858l1-7C21.031,5.854,20.945,5.563,20.756,5.345z M18.847,7l-0.285,2H15V7H18.847z M14,7    v2h-3V7H14z M14,10v2h-3v-2H14z M10,7v2H7C6.947,9,6.899,9.015,6.852,9.03L6.514,7H10z M7.014,10H10v2H7.347L7.014,10z M15,12v-2    h3.418l-0.285,2H15z"></path><circle cx="8.5" cy="19.5" r="1.5"></circle><circle cx="17.5" cy="19.5" r="1.5"></circle></g></g></svg>ADDING...';
					}else{
						button.innerHTML = 'ADDING...';
					}
					
				}
			  }
			  
			  function hideSpinner(button) {
				if (button){
					button.disabled = false;
					if (button.getAttribute('data-icon')){
						button.innerHTML = '<svg baseProfile="tiny" height="24px" version="1.2" viewBox="0 0 24 24" width="24px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><g><path d="M20.756,5.345C20.565,5.126,20.29,5,20,5H6.181L5.986,3.836C5.906,3.354,5.489,3,5,3H2.75c-0.553,0-1,0.447-1,1    s0.447,1,1,1h1.403l1.86,11.164c0.008,0.045,0.031,0.082,0.045,0.124c0.016,0.053,0.029,0.103,0.054,0.151    c0.032,0.066,0.075,0.122,0.12,0.179c0.031,0.039,0.059,0.078,0.095,0.112c0.058,0.054,0.125,0.092,0.193,0.13    c0.038,0.021,0.071,0.049,0.112,0.065C6.748,16.972,6.87,17,6.999,17C7,17,18,17,18,17c0.553,0,1-0.447,1-1s-0.447-1-1-1H7.847    l-0.166-1H19c0.498,0,0.92-0.366,0.99-0.858l1-7C21.031,5.854,20.945,5.563,20.756,5.345z M18.847,7l-0.285,2H15V7H18.847z M14,7    v2h-3V7H14z M14,10v2h-3v-2H14z M10,7v2H7C6.947,9,6.899,9.015,6.852,9.03L6.514,7H10z M7.014,10H10v2H7.347L7.014,10z M15,12v-2    h3.418l-0.285,2H15z"></path><circle cx="8.5" cy="19.5" r="1.5"></circle><circle cx="17.5" cy="19.5" r="1.5"></circle></g></g></svg>ADD TO CART';
					}else{
						if (button.getAttribute('data-quantity')){
							button.innerHTML = 'ADD '+button.getAttribute('data-quantity');
						}else{
							button.innerHTML = 'ADD TO CART';
						}
						
					}
				}
			  }
			function handleClick(e) {
				//get data-sku attribute from button
				
				//debugger;
				console.log(e)
				const button = e.target? e.target:e;
				const btnType = button.getAttribute('data-type');
				const quantityElementId = button.getAttribute('data-quantity-element');
				const quantity = button.getAttribute('data-quantity');
				let quantityElement = null;
				if (btnType){
					if (quantityElementId){					
						if (btnType === 'product-page'){
							const quantityElement1 = document.getElementById(quantityElementId);
							if (quantityElement1) {
								if (quantity){
									quantityElement1.value = quantity;
									quantityElement1.dispatchEvent(new Event('change'));
								}
								quantityElement = {target:quantityElement1};
							}
						}
						if (btnType === 'post-type-filter-grid'){							
							const quantityElement2 = button.parentElement.querySelector('#'+quantityElementId);
							if (quantityElement2) {
								quantityElement = quantityElement2;
							}
						}
					}
				}


				var parentElement = document.querySelector('section.product-details');
				if (parentElement && isChildOf(parentElement, e.target)){
					//product details page
					//find the proper button and click it
					if(isCommerce7V2()){
						waitForElement('section.product-details .c7-product__add-to-cart button.c7-btn', e.target, (element) => {												
							handleQuantityChange(quantityElement,0);
							setTimeout(function(){ 
								element.click(); }, 500
							);
						});
					}
					else{
						waitForElement('section.product-details .c7-product__variant__add-to-cart button.c7-button', e.target, (element) => {												
							handleQuantityChange(quantityElement,0);
							setTimeout(function(){ 
								element.click(); }, 500
							);
						});
					}

				}else{				
					if (e.target){
						if(isCommerce7V2()){
							//debugger;
							waitForNestedElement(e.target.parentElement.parentElement, 'button.c7-btn', e.target, (element) => {
								if (quantityElement) handleQuantityChange(quantityElement,0);
								setTimeout(function(){ 
									element.click(); }, 500
								);
							});
						}
						else{
							//debugger;
							waitForNestedElement(e.target.parentElement, 'button.c7-button', e.target, (element) => {
								if (quantityElement) handleQuantityChange(quantityElement,0);
								setTimeout(function(){ 
									element.click(); }, 500
								);
							});
						}
					}else{
						if(isCommerce7V2()){
							//debugger;
							waitForNestedElement(e.parentElement.parentElement, 'button.c7-btn', e, (element) => {
								if (quantityElement) handleQuantityChange(quantityElement,0);

								setTimeout(function(){ 
									element.click(); }, 500
								);
								
							});
						}
						else{
							//debugger;
							waitForNestedElement(e.parentElement.parentElement, 'button.c7-button', e, (element) => {
								if (quantityElement) handleQuantityChange(quantityElement,0);

								setTimeout(function(){ 
									element.click(); }, 500
								);
								
							});
						}
					}
				}
			}		

			function isChildOf(parentElement, elementToCheck) {
				if (parentElement === elementToCheck) {
				  return true;
				} else if (elementToCheck.parentNode) {
				  return isChildOf(parentElement, elementToCheck.parentNode);
				} else {
				  return false;
				}
			}

			function findFirstPropertyStartingWithPattern(obj, pattern) {
				const regex = new RegExp(`^${pattern}`);
				
				for (const propName in obj) {
					if (obj.hasOwnProperty(propName) && regex.test(propName)) {
					return propName;
					}
				}
				
				return undefined; // Property not found
			}
			  
			function handleQuantityChange(e,increment ) {
							
				//debugger;

				const qType = e.target? e.target.getAttribute('data-type'):e.getAttribute('data-type');
				
				if (qType === 'product-page'){
					var parentElement = document.querySelector('section.product-details');
					if (parentElement && isChildOf(parentElement, e.target ? e.target:e)){

						if (increment!=undefined && increment!=null && increment!=0) {

							let theNewValue = parseInt(document.getElementById('add-to-cart-quantity').value) + parseInt(increment)

							if (theNewValue < 1) theNewValue = 1;

							document.getElementById('add-to-cart-quantity').value=theNewValue;							
							document.getElementById('add-to-cart-quantity').dispatchEvent(new Event('change'));

							return;
						}

						const quantityField = document.querySelector('section.product-details input[name="quantity"]');

						if (quantityField){
							let newValue = e.target? e.target.value : e.value;
							let quantityLabel;
							let property;
							if (newValue < 1) newValue = 1;
							if(isCommerce7V2()){
								quantityLabel = document.querySelector('section.product-details .c7-product__add-to-cart .c7-product__add-to-cart__form__quantity input');
								let property = findFirstPropertyStartingWithPattern(quantityLabel, '__reactProps');
								if (property){							
									quantityLabel[property].onChange({target: {value: newValue}})
								}
							}
							else{
								quantityLabel = document.querySelector('section.product-details .c7-product__variant__add-to-cart label');
								let property = findFirstPropertyStartingWithPattern(quantityLabel, '__reactProps');
								if (property){							
									quantityLabel[property].children.props.onChange({target: {value: newValue}});
								}
							}					
						}
					}
				}
				if (qType === 'post-type-filter-grid'){
					let newValue2 = e.value;
					let quantityLabel2;
					if(isCommerce7V2()){
						//quantityLabel2 = e.parentElement.parentElement.parentElement.querySelector('.c7-product__add-to-cart label[for=quantity]');
						//console.log(quantityLabel2)
						console.log(e)
						quantityLabel2 = e.parentElement.parentElement.parentElement.querySelector('.c7-product__add-to-cart input[name=quantity]')
						console.log(quantityLabel2)
					}
					else{
						quantityLabel2 = e.parentElement.parentElement.parentElement.querySelector('.c7-product__variant__add-to-cart label[for=quantity]');
					}
					if (quantityLabel2){
						let property2 = findFirstPropertyStartingWithPattern(quantityLabel2, '__reactProps');
						if (property2){				
							if(isCommerce7V2()){			
								quantityLabel2[property2].onChange({target: {value: newValue2}});
							}
							else{
								quantityLabel2[property2].children.props.onChange({target: {value: newValue2}});
							}
						}
					}
				}
			}

    function init() {		

		const buttons = document.querySelectorAll("button.add-to-cart[data-sku]");	
		

		//for each button add click event handler
		for (let i = 0; i < buttons.length; i++) {
			if (buttons[i].getAttribute("data-binded")) {
				continue;
			}else{

				buttons[i].addEventListener("click", handleClick);
				buttons[i].setAttribute("data-binded", "true");

				const btnType = buttons[i].getAttribute('data-type');
				const quantityElementId = buttons[i].getAttribute('data-quantity-element');

				if (btnType){
					if (quantityElementId){					
						if (btnType === 'product-page'){
							const quantityElement = document.getElementById(quantityElementId);
							if (quantityElement) quantityElement.addEventListener("change", handleQuantityChange);
						}
						if (btnType === 'post-type-filter-grid'){
							
							const quantityElement = buttons[i].parentElement.querySelector('#'+quantityElementId);
							if (quantityElement) quantityElement.addEventListener("change", handleQuantityChange);
						}
					}
				}
			}
		}
    }   

	//when document loaded call init
	document.addEventListener("DOMContentLoaded", init);	

	window.commerce7cart = {};
	window.commerce7cart.init = init;
	window.commerce7cart.handleClick = handleClick;
	window.commerce7cart.handleQuantityChange = handleQuantityChange;

}