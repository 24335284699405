import { checkDevice } from "@pernod-ricard-global-cms/jsutils";
import lazyloader from "@pernod-ricard-global-cms/cbllazyloader";
import jsAssets from "Assets/js/modules/jsAssets";
import checkBrowserInfo from "Assets/js/modules/check-browser-info";
import colourPalettes from "@pernod-ricard-global-cms/cblcolourpalettes";
import siteHeader from "Assets/js/components/site-header";
import { ttfb } from "Assets/js/modules/perf-tests";
import tock from "Assets/js/modules/tock";
import bazaarvoice from "Assets/js/modules/bazaarvoice";
import checkout from './components/checkout';
import profileCustomization from './components/profileCustomization';
import tockButtons from './components/tockButtons';
import zendesk from "Assets/js/modules/zendesk";
import commerce7cart from "./modules/commerce7cart";
import evidon_fix from "./modules/evidon_fix";
import clubExclusive from "./modules/club-exclusive";
ttfb();
checkDevice();
checkBrowserInfo();
globalThis.requestAnimationFrame(() => {
    const colourData = globalThis.colourconfig;
    colourPalettes(colourData);
    const header = document.querySelector(".site-header");
    siteHeader({ block: header });
    lazyloader.options.assetArray = jsAssets.dynamicAssets;
    lazyloader.options.rootMargin = "100% 0px 100px 0px";
    lazyloader.options.filePath = "js/blocks/";
    lazyloader.options.debugLogMessages = false;
    lazyloader.lazyloaderInit();
});
function safeRun(callback) {
    try {
        callback();
    }
    catch (e) {
        console.log(e);
    }
}
safeRun(() => checkout());
safeRun(() => profileCustomization());
safeRun(() => tockButtons());
safeRun(() => tock());
safeRun(() => bazaarvoice());
safeRun(() => zendesk());
safeRun(() => commerce7cart());
safeRun(() => evidon_fix());
safeRun(() => clubExclusive());
