export default function(){
    function fixEvidonBanner(){
        var fix_success = false;
        var evidonMessage = document.querySelectorAll("#_evidon-message");
        if(evidonMessage.length > 0){
            if(evidonMessage[0].innerHTML === ''){
                var evidonBanner = document.querySelectorAll("#_evidon_banner");
                evidonBanner[0].style.display = 'none';
                fix_success = true;
            }
        }
        if (fix_success == false){
                setTimeout(function(){
                    fixEvidonBanner();
            }, 100);
        }
      }
    fixEvidonBanner();
/*
    evidonMessage = document.querySelectorAll("#_evidon-message");
    if(evidonMessage.length > 0){
        if(evidonMessage[0].innerHTML === ''){
            evidonBanner = document.querySelectorAll("#_evidon_banner");
            evidonBanner[0].style.display = 'none';
        }
    }
    // Select the target node.
    var target = document.querySelector('#_evidon-message')

    // Create an observer instance.
    var observer = new MutationObserver(function(mutations) {
        evidonMessage = document.querySelectorAll("#_evidon-message");
        if(evidonMessage.length > 0){
            if(evidonMessage[0].innerHTML === ''){
                evidonBanner = document.querySelectorAll("#_evidon_banner");
                evidonBanner[0].style.display = 'none';
            }
        }
        console.log("Evidon hidden") 
    });

    // Pass in the target node, as well as the observer options.
    observer.observe(target, {
        attributes:    true,
        childList:     true,
        characterData: true
    });*/
}